.fondo-dialogo-pasarela
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1000
    position: fixed
    background-color: rgba(0, 0, 0, 0.2)
    -webkit-tap-highlight-color: transparent
    display: flex
    flex-direction: column
    overflow: auto
    padding: 2em 0
    @import ./components/Tarjeta/tarjeta
    @import ./components/Titular/titular
    @import ./components/aprobado/aprobado

    .fondo-dialogo-pasarela-cerrar
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 1001
        position: absolute
        -webkit-tap-highlight-color: transparent
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

    .contenedor-dialogo-pasarela
        display: flex
        flex-direction: column
        align-items: center
        width: 54%
        padding: 1em 1em
        background-color: white
        z-index: 1302
        margin: auto
        font-size: 16px
        border-bottom-right-radius: 1rem
        border-bottom-left-radius: 1rem
        border-top-right-radius: 1rem
        border-top-left-radius: 0.1rem
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.5)

        @media screen and ( max-width: 800px )
            width: 80%

        .titulo-dialogo-pasarela
            text-align: center
            font-size: 1.35em
            font-weight: bolder
            color: #ED312E
            margin-top: 2em
            margin-bottom: 1em
            @media screen and ( max-width: 800px )
                margin: 1em 0

        .contenedor-botones
            display: flex
            width: 80%
            justify-content: space-evenly
            font-size: 1em

            .boton-dialogo
                border-top-right-radius: 10px
                border-bottom-left-radius: 10px
                border-bottom-right-radius: 10px
                border-top-left-radius: 2px
                padding: 0.7em 2em
                background-color: $secundario
                color: white
                border: none
                font-weight: 550
                margin: 1em 0
                outline: none
                cursor: pointer
                font-size: 1em
                width: fit-content
                @media screen and ( max-width: 800px )
                    font-size: 0.7em
                @media screen and ( max-width: 300px )
                    font-size: 0.5em

            .boton-dialogo-morado
                border-top-right-radius: 10px
                border-bottom-left-radius: 10px
                border-bottom-right-radius: 10px
                border-top-left-radius: 2px
                padding: 0.7em 2em
                background-color: #887AB8
                color: white
                border: none
                font-weight: 550
                margin: 1em 0
                outline: none
                cursor: pointer
                font-size: 1em
                width: fit-content
                @media screen and ( max-width: 800px )
                    font-size: 0.7em
                @media screen and ( max-width: 300px )
                    font-size: 0.5em
            .boton-dialogo-inactivo
                border-top-right-radius: 10px
                border-bottom-left-radius: 10px
                border-bottom-right-radius: 10px
                border-top-left-radius: 2px
                padding: 0.7em 2em
                background-color: rgba(189, 195, 199, 0.7)
                color: white
                border: none
                font-weight: 550
                margin: 1em 0
                outline: none
                cursor: pointer
                font-size: 1em
                width: fit-content
                @media screen and ( max-width: 800px )
                    font-size: 1em
                @media screen and ( max-width: 300px )
                    font-size: 0.5em

        .label
            text-align: center
            font-size: 1em
            color: $secundario
            margin-bottom: 1em
        .label-pointer
            text-align: center
            font-size: 1em
            font-weight: 350
            color: $secundario
            margin-bottom: 1em
            color: #887AB8
            cursor: pointer
