.fondo-dialogo-tour
    background-color: transparent
    display: flex
    align-items: center
    justify-content: center

    .transparencia-dialogo-tour
        background-color: #0F054133

    .contenido-dialogo-tour
        border-radius: 10px
        border: 5px solid white
        position: relative
        z-index: 10000
        background: white
        @media screen and (min-width: 640px)
            border: 10px solid white
        >img
            position: absolute
            width: 40px
            height: 40px
            cursor: pointer
            @media screen and (min-width: 640px)
                width: 52px
                height: 52px
        >.icono-cerrar-dialogo
            top: -20px
            right: -20px
            @media screen and (min-width: 640px)
                top: -26px
                right: -26px
        >.video-dialogo
            width: 800px
            max-width: 85vw
            aspect-ratio: 16/9
        >.collage-dialogo
            max-width: 80vw
            width: max-content
            @media screen and (min-width: 640px)
                max-width: 70vw
            @media screen and (min-width: 1024px)
                max-width: 50vw
            >img
                width: 100%
                height: 100%
                max-height: 80vh

        >.right-arrow-tour
            right: -30px
            transform: rotateY(180deg) translateY(-50%)
            @media screen and (min-width: 640px)
                right: -70px
        >.left-arrow-tour
            left: -30px
            transform: translateY(-50%)
            @media screen and (min-width: 640px)
                left: -70px
        >.arrow-tour
            top: 50%
