.contenedor-tarjeta-comentario-tour
    font-size: 1em
    position: relative
    width: 95%
    max-width: 40em
    justify-self: center

    >.contenedor-globo-comentario
        background-color: $rosado-claro
        font-size: 1em
        width: 7em
        height: 7em
        position: absolute
        border: 0.5em solid white
        +border-radius(50%)
        background-position: center
        background-size: cover
        top: 1.5em
        left: 1em

    >.fecha-comentario-tour
        font-size: 1.2em
        letter-spacing: 2px
        padding-left: 9.1em
        margin-top: 1em
        margin-bottom: 0.5em
        min-height: 1em

    >.contenedor-texto-comentario-tour
        font-size: 1em
        +border-radius(1em)
        +border-top-right-radius(0.25em)
        background: linear-gradient(to bottom, rgba(179, 104, 169, 0.4), rgba(136, 122, 184, 0.4))
        margin-left: 3em
        min-height: 8em
        padding-left: 8em
        box-sizing: border-box
        min-width: 90%

        >.nombre-comentario-tour
            margin-top: 1em
            font-size: 1.3em
            letter-spacing: 2px
            margin-bottom: 0.25em
            width: 95%

        >.texto-comentario-tour
            font-size: 0.9em
            width: 94%
            margin-bottom: 1em

    >.campo-skeleton
        background: none
        background-color: #ddd !important

    >.onda-skeleton
        background-size: 1000px !important
        animation: shine-avatar 3s infinite linear
        background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px) !important
