.ordenar
    position: relative
    width: 7em
    min-width: fit-content

    .boton-ordenar
        position: relative
        z-index: 12
        font-size: 0.8em
        padding: 0.8em 0.8em
        background-color: $primario
        border-radius: 1.875em
        cursor: pointer
        user-select: none

        img
            width: 1.75em

        span
            margin-left: 0.75em
            color: white
            letter-spacing: 0.08em
            font-weight: bold

    .lista-ordenar
        position: absolute
        width: 100%
        z-index: 11
        padding: 3em 0em 0.5em 0em
        border-radius: 1.5625em 1.5625em 1.25em 1.25em
        row-gap: 0.5em

        background-color: #FFF
        @include box-shadow(0px 3px 6px #00000029)
        
        *
            color: $primario

        .opcion-ordenar
            cursor: pointer
            margin: auto
            width: 80%

            svg
                height: 0.75em
                cursor: pointer

            span
                margin-left: 1em
                font-size: 0.7em
