.lista-ciudades
    max-width: 78.125em
    width: 85%
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(14.0625em, 1fr))
    row-gap: 4em
    column-gap: 5em
    margin: 1em 0 5em 0
    justify-items: center
    font-size: 16px

    @media screen and (max-width: 650px)
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
        font-size: 12px
        width: 95%
        column-gap: 25px
        row-gap: 2.5em

    @media screen and (max-width: 325px)
        grid-template-columns: repeat(auto-fill, minmax(125px, 1fr))
        column-gap: 10px

    .contenedor-ciudad
        cursor: pointer
        width: 14em
        word-break: break-word

        @media screen and (max-width: 325px)
            width: 12em


        img
            border-radius: 5px 10px 10px 10px
            width: 5em
            height: 5em
            margin-right: 1em
            object-fit: cover

            @media screen and (max-width: 325px)
                width: 4.5em
                height: 4.5em



        div
            color: $primario
            @media screen and (max-width: 350px)
                font-size: 11px

            &:nth-child(1)
                font-weight: bold
                letter-spacing: 0.05em

            &:nth-child(2)
                letter-spacing: 0.05em
                font-weight: lighter

    .contenedor-ciudad-selected
        border: 0.1875em outset $primario
        border-radius: 0.3125em 1.25em 1.25em 1.25em
        box-sizing: border-box

        img
            border-radius: 0.3125em 0 0 1.25em
            transform: scale(1.05)
            z-index: -1
            position: relative

    @media screen and (max-width: 700px)
        .lista-sitios
            grid-template-columns: repeat(auto-fit, minmax(175px, 1fr))


