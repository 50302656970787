.contenedor-detalle-tour
    width: 100%
    font-size: 16px

    @media screen and ( max-width: 1000px)
        flex-direction: column

    @media screen and ( max-width: 500px )
        font-size: 3.2vw


    @import ./components/encabezadoTour/encabezado-tour
    @import ./components/comentariosTour/comentarios-tour
    @import ./components/pasarela/pasarela
    @import ./components/dialogoTour/dialogo-tour

    >.contenedor-info-mapa-tour
        font-size: 1em
        width: 85%
        margin-bottom: 3em

        @import ./components/infoTour/info-tour
        @import ./components/videoTour/video-tour
        @media screen and ( max-width:  1000px)
            flex-direction: column

        .contenedor-mapa-tour
            @import ./../general/mapGeneral/point/point

            > div > div
                +border-radius(50px 50px 50px 5px)
                cursor: default
    
    >.contenedor-ruta
        display: flex
        width: 100%
        flex-direction: column
        align-items: center

        .titulo-lugares-tour
            font-size: 2em
            letter-spacing: 3px
            width: 20em
            background-color: $primario
            padding: 0.8em 6vw
            border-bottom-right-radius: 5em
            border-top-right-radius: 0.25em
            box-sizing: border-box
            max-width: 100%
            text-transform: uppercase
            min-width: 13em
            min-height: 2.6em
            align-self: flex-start

        .mapa-visitar
            width: 70%
            height: 500px
            margin: 4em 0
            @media screen and ( max-width:  800px)
                width: 80%
                margin: 2em 0

            @import ./../general/mapGeneral/point/point

        .mapa-visitar > div > div
            +border-radius(43px)