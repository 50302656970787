.contenedor-encabezado-tour
    border-bottom-left-radius: 5em
    overflow: hidden
    font-size: 16px
    position: relative
    height: 507px
    width: 100%
    background-repeat: no-repeat
    background-size: cover
    background-position: center

    >.button-play
        position: absolute
        width: 90px
        height: 90px
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        cursor: pointer
    svg, svg path
        transition: all ease 0.3s
    svg:hover path
        fill: #6DACD8
    svg:hover
        width: 100px
        height: 100px

    >.gradiente
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: calc( 100% - 2em )
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, $morado-oscuro 100%)
        padding-bottom: 2em
        color: white
        cursor: pointer

    >.gradiente-modificado
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, #ddd 100%)

    >.contenedor-precio-tour
        background-color: $secundario
        border-bottom-right-radius: 1.5em
        font-size: 1.3em
        padding: 0.7em 0
        width: 10.7em
        height: 1.3em
        margin-top: 84px
        z-index: 10
        @media screen and ( max-width: 869px )
            margin-top: 92px

        @media screen and ( max-width: 800px )
            margin-top: 60px

    >.corazon-detalle-tour
        position: absolute
        bottom: 0.5em
        left: 150px
        width: 1em
        cursor: pointer
        @media screen and ( max-width: 800px )
            left: 15%
.onda-skeleton
    background-size: 1000px
    animation: shine-avatar 3s infinite linear
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)

@keyframes shine-avatar
    0%
        background-position: -100px

    40%, 100%
        background-position: 800px
