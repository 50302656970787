.contenedor-mapa-tour
    font-size: 1em
    margin-top: -6em
    z-index: 1
    width: 50%
    margin-bottom: 2em
    @media screen and ( max-width: 1000px)
        width: 100%
        margin-top: 0em
    >.contenedor-collage-tour
        border-radius: 50px 50px 50px 5px
        border: 6px solid white
        width: 800px
        max-width: 100%
        aspect-ratio: 16/9
        box-shadow: 0px 3px 6px #00000029
        display: grid
        grid-template-columns: 2fr 1fr
        grid-template-rows: 1fr 1fr
        column-gap: 5px
        row-gap: 5px
        background: white
        cursor: pointer
        @media screen and ( min-width: 640px)
            border: 12px solid white
            column-gap: 9px
            row-gap: 9px
        >img
            width: 100%
            height: 100%
            object-fit: cover
        >.img-tour-1
            grid-area: img1
        >.img-tour-2
            grid-area: img2
            border-radius: 5px 42px 5px 5px
        >.img-tour-3
            grid-area: img3
            border-radius: 5px 5px 42px 5px
    >.contenedor-estrellas-tour
        font-size: 1em
        width: 45vw
        margin-bottom: 1em
        margin-top: 20px
        @media screen and ( max-width: 1000px)
            align-self: center
            width: 27em
    >.texto-calificar-tour
        font-size: 0.7em
        cursor: pointer
    >.boton-comprar-tour
        font-size: 1.2em
        margin-top: 1em
        border: none
        width: 85%
        padding: 1em 0
        border-radius: 1em
        border-top-left-radius: 0.2em
        cursor: pointer
        text-transform: uppercase
        min-height: 4em
        @media screen and ( max-width: 1000px)
            max-width: 22em
            margin-top: 0
            align-self: center
    >.campo-skeleton
        border-radius: 0.5em
        background-color: #ddd

    >.contenedor-video
        font-size: 1em
        width: 43vw
        height: 25vw
        max-height: 24em
        border-radius: 3.2em
        border-bottom-left-radius: 0.2em
        margin-bottom: 1em
        background-size: 800px
        animation: shine-avatar-big 3s infinite linear
        background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)

        @media screen and ( max-width: 1000px)
            width: 27em
            height: 15em
            align-self: center

        iframe
            border-radius: 50px 50px 50px 5px

    .map-container
        width: 100%
        position: sticky
        height: 40vh
        right: 0
        top: 0
        +border-radius(2em)
        +border-bottom-left-radius(0.2em)
        overflow: hidden

@keyframes shine-avatar-big
    0%
        background-position: -50px

    40%, 100%
        background-position: 700px
