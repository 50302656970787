.contenedor-audio
    display: flex
    align-items: center
    flex-wrap: wrap
    padding: 10px 0

    span
        margin-right: 10px
        color: $secundario
        font-weight: bold
        padding: 10px 0