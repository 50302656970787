.contenedor-comentarios-tour
    font-size: 1em
    width: 100%

    >.titulo-comentarios-tour
        font-size: 2em
        letter-spacing: 3px
        width: 35vw
        background-color: $primario
        padding: 0.8em 8vw
        border-bottom-right-radius: 5em
        border-top-right-radius: 0.25em
        box-sizing: border-box
        max-width: 100%
        text-transform: uppercase
        min-width: 13em
        min-height: 2.6em

    >.grilla-comentarios-tour
        width: 90%
        font-size: 1em
        margin: 4em 0 8em
        display: grid
        column-gap: 1vw
        row-gap: 1em
        grid-template-columns: repeat(auto-fill ,minmax(35em,1fr))
        @import ./tarjeta-comentario-tour
        @media screen and ( max-width: 600px )
            grid-template-columns: 1fr
            margin-left: -2em

    .mensaje-sin-comentarios
        display: flex
        flex-direction: column
        text-align: center
        width: fit-content
        max-width: 90%
        margin: 100px auto
        font-size: 16px
        color: $secundario
        font-size: 1.5625em
            
            

