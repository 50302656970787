.contenedor-decripcion-about
    font-size: 1em
    width: 100%
    margin: 5em 0 6em
    @media screen and ( max-width: 1000px )
        margin-bottom: 4em

    >.elementos-descripcion-about
        width: 100%
        font-size: 1vw
        position: relative
        @media screen and ( max-width: 1000px )
            flex-direction: column
            justify-content: center
            align-items: center

        >.contenedor-hover-celular-descripcion
            position: absolute
            font-size: 1em
            width: 100%
            height: 100%
            left: 0
            top: 0
            @media screen and ( max-width: 1000px )
                position: relative
            &>.contenedor-celular-descripcion
                font-size: 1em
                width: 100%
                height: 100%
                padding-right: 160em
                transition: all 0.5s linear
                margin-bottom: 5em
                padding-top: 3em
                @media screen and (max-width: 1000px)
                    padding-top: 0
                    margin-top: 3em
                    margin-bottom: 0
                    display: flex
                    justify-content: center
                    padding-left: 0 !important
                >img
                    font-size: 1em
                    width: 21vw
                    object-fit: contain
                    transform: rotate(30deg)
                    @media screen and ( max-width: 1000px )
                        width: 30vw

        >.contenedor-video-texto-descripcion
            width: 50%
            font-size: 1em
            margin-right: 10vw
            z-index: 1

            .botones-echa-un-vistazo
                margin-top: 30px
                display: flex
                justify-content: space-between
                flex-wrap: wrap
                margin-left: 20px
                margin-right: 20px
                min-width: 550px

                .item-echa-un-vistazo
                    flex-grow: 1
                    display: flex
                    flex-direction: column
                    align-items: center

                    button
                        background-color: $primario
                        color: white
                        width: 10em
                        height: 3em
                        border-radius: 30px
                        border: none
                        cursor: pointer
                        text-transform: uppercase
                        font-size: 14px
                        font-weight: bold
                        letter-spacing: 2.1px

                        &:hover
                            background-color: $secundario

                            & + p
                                opacity: 1

                    p
                        margin-top: 30px
                        text-align: center
                        width: 12em
                        font-weight: 200
                        opacity: 0
                        transition: opacity 0.5s
                        font-size: 16px

                @media screen and (max-width: 550px)

                    flex-direction: column
                    min-width: none

                    .item-echa-un-vistazo
                        p
                            opacity: 1
                            margin-bottom: 30px

            @media screen and ( max-width: 1000px )
                width: 100%
                align-items: center
                margin-right: 0
                font-size: 2vw
                margin-top: 3em
            >.contenedor-video
                font-size: 1em
                width: 41em
                height: 22em
                margin-bottom: 3em
                transition: none
            >.titulo-descripcion-about
                width: 17.5em
                font-size: 2.3em
                margin-bottom: 0.7em
                @media screen and ( max-width: 1200px )
                    font-size: 25px
                @media screen and ( max-width: 1000px )
                    text-align: left
                    width: 80%
                    font-size: 35px
                @media screen and ( max-width: 500px )
                    font-size: 6vw
            >.texto-descripcion-about
                letter-spacing: 3px
                font-size: 1em
                width: 44em
                line-height: 1.5em
                z-index: -1
                @media screen and ( max-width: 1200px )
                    font-size: 12px
                @media screen and ( max-width: 1000px )
                    text-align: left
                    font-size: 20px
                    width: 80%
                @media screen and ( max-width: 500px )
                    text-align: left
                    font-size: 3.8vw
                    width: 80%
            >p.texto-descripcion-about
                margin-bottom: 1em
