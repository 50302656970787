.contenedor-icono{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transform: translate(-50%, -100%) scale(1);
    width: fit-content;
    height: fit-content;

    .titulo{
        position: absolute;
        top: -35px;
        color: transparent;
        text-align: center;
        @include border-radius(10px);
        background: transparent;
        padding: 0.3em 0.5em;
        width: max-content;
        z-index: 5;
    }

    .sin-titulo{
        color: $primario;
        background: white;
        width: max-content;
    }

    .icono {
        width: 50px;
        cursor: pointer;
        transition: all 1s ease;
        z-index: 10;
    }
    
    .icono:hover{
        transform: scale(1.2);
    }

    .escalar{
        transform: scale(1.2);
    }
}