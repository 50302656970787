.filtros-tours
    grid-template-columns: 1fr 1fr 1fr 1fr
    
    @media screen and ( max-width: 1200px )
        grid-template-columns: 1fr 1fr

    @media screen and ( max-width: 800px )
        grid-template-columns: 1fr

.filtros-places
    grid-template-columns: 1fr 1fr

    @media screen and ( max-width: 800px )
        grid-template-columns: 1fr

.filtros
    position: relative
    z-index: 10
    margin: 1.5em 1em 3em 1em
    border: 4px solid $terciario
    @include border-radius(5px 20px 20px 20px)
    max-width: 80%
    display: grid
    align-items: center
    justify-content: center
    padding: 0.25em 0em
    row-gap: 0.5em

    div:last-of-type
        border-right: none

    .contenedor-filtro
        position: relative
        border-right: solid red 3px

        @media screen and ( max-width: 1200px )
            border-right: none

        #elemento-ciudad
            z-index: 10

        #lista-filtro-paises
            z-index: 10
            
        #lista-filtro-ciudades
            z-index: 9

        #elemento-duracion
            z-index: 8

        #lista-filtro-duracion
            z-index: 7

        #elemento-categoria
            z-index: 6

        #lista-filtro-categoria
            z-index: 5

        .elemento-filtro
            user-select: none
            position: relative
            display: flex
            align-items: center
            cursor: pointer
            background-color: #FFF
            border-radius: 0.625em

            .arrow-down
                border: solid #ED312E
                border-width: 0 5px 5px 0
                display: inline-block
                padding: 6px
                cursor: pointer
                transform: rotate(45deg)
                -webkit-transform: rotate(45deg)
                margin: 0 1.25em
            
            .icono-filtro
                width: 3em
                margin-right: 0.5em
                margin-left: 0.5em


            .texto-filtro
                display: flex
                flex-direction: column
                width: 100%

                span:first-child
                    color: $terciario
                    font-weight: bold
                    font-size: 1.2em
                
                span:nth-child(2)
                    color: $primario

                input
                    font-size: 1em
                    color: $primario
                    width: 100%
                    border: none

                    &::placeholder
                        color: $primario

        .lista-filtro
            position: absolute
            z-index: 5
            width: 100%
            padding: 4em 0em 0.5em 0em
            border-radius: 1.5625em 1.5625em 0.1563em 1.25em
            background-color: #FFF
            @include box-shadow(0px 3px 6px #00000029)
            max-height: 8em
            overflow-y: scroll

            /* width */
            &::-webkit-scrollbar 
                width: 5px

            /* Track */
            &::-webkit-scrollbar-track 
                border-radius: 10px
            
            /* Handle */
            &::-webkit-scrollbar-thumb 
                background: $primario 
                border-radius: 10px 
            
            *
                color: $primario

            .opcion-filtro
                font-size: 0.9em
                cursor: pointer
                padding: 0.5em 1em
                border-bottom: solid 1px $primario

                svg
                    height: 0.75em
                    cursor: pointer

                span
                    margin-left: 1em
                    font-size: 0.7em
                
                &:last-child
                    border-bottom: none

                &:hover
                    background-color: $primario
                    color: white

