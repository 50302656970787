.user-profile {
    .contenedor-perfil {
        position: relative;
        width: 100%;
        height: 15em;
        border-bottom-left-radius: 5em;
        background-position: center;
        background-size: cover;
        .imagen-perfil {
            min-width: 98%;
        }
        .boton-editar {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 1.25em;
            font-weight: 550;
            color: white;
            border: none;
            border-top-left-radius: 1.5em;
            background-color: #58c7de;
            width: fit-content;
            cursor: pointer;
            align-content: center;
            padding-top: 0.7rem;
            padding-left: 1.5em;
            padding-right: 1rem;
            outline: none;
            padding-bottom: 0.7rem;
            @media screen and (max-width: 470px) {
                font-size: 1em;
                padding-right: 1em;
                padding-left: 1em;
            }
        }
        .contenedor-imagen {
            position: absolute;
            bottom: -6.25em;
            left: 17%;
            //z-index: 101;
            height: 12.5em;
            width: 12.5em;
            box-sizing: border-box;
            border: 8px solid white;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            @media screen and (max-width: 650px) {
                left: 6%;
                border: 6px solid white;
            }
            @media screen and (max-width: 450px) {
                height: 8em;
                left: 7%;
                border-radius: 50%;
                //z-index: 101;
                width: 8em;
                border: 6px solid white;
                bottom: -4em;
            }
        }
        .editar-imagen {
            position: absolute;
            bottom: -6.25em;
            left: 17%;
            //z-index: 101;
            height: 12.5em;
            width: 12.5em;
            //z-index: 101;
            box-sizing: border-box;
            border: 8px solid white;
            border-radius: 50%;
            background: linear-gradient(180deg, rgba(179, 104, 169, 1), rgba(136, 122, 184, 1));
            background-position: center;
            background-size: cover;
            display: grid;
            place-items: center;

            @media screen and (max-width: 650px) {
                left: 6%;
                border: 6px solid white;
            }
            @media screen and (max-width: 450px) {
                height: 8em;
                left: 7%;
                border-radius: 50%;
                //z-index: 101;
                width: 8em;
                border: 6px solid white;
                bottom: -4em;
            }

            .agregar-metodo {
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                transition: 0.3s;
                float: right;
                margin: auto;
                font-size: 17px;
                justify-items: center;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100%;
                height: 100%;
                .input-imagen{
                    opacity:0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .mas {
                    cursor: pointer;
                    align-self: center;
                    justify-self: center;
                    width: 30px;
                    height: 30px;
                    display: grid;
                    place-items: center;
                    box-sizing: border-box;
                    border-radius: 3px;
                    border: 3px solid white;
                    color: white;
                    line-height: 20px;
                    font-weight: 550;
                    font-size: 1em;
                }
                .texto-agregar {
                    color: white;
                    margin-top: 0.625em;
                    margin-left: 10%;
                    margin-right: 10%;
                    text-align: center;
                }
            }
        }
    }
    .edit-profile {
        width: fit-content;
        margin-left: 37.5%;
        margin-bottom: 1.5rem;
        margin-top: 1rem;

        @media screen and (max-width: 950px) {
            margin-left: auto;
            margin-right: 10px;
        }
        @media screen and (max-width: 370px) {
            margin-left: auto;
            margin-top: 3em;
            margin-right: 10px;
        }

        > span {
            margin-bottom: 10px;
        }

        .input-phonenumber-profile-red {
            width: 100%;
            height: fit-content;
            border-radius: 1rem;
            padding: 0.3rem 0.7rem;
            border: 1px solid #ed312e;
            box-sizing: border-box;
            color: #ed312e;
            transition: 0.3s;
            font-size: 1em;
            margin: 0rem 0rem;

            
            @media screen and (max-width: 800px) {
                
                width: 65%;
            }
        }
        .input-phonenumber-profile-red:focus {
            border-color: #ed312e;
            box-shadow: 0 0 8px 0 #ed312e;
        }
        .input-phonenumber-profile {
            //height: fit-content;
            border-radius: 1rem;
            padding: 0.3rem 0.7rem;
            border: 1px solid #eee1f4;
            box-sizing: border-box;
            transition: 0.3s;
            color: #887ab8;
            font-size: 1em;
            margin: 0rem 0.4rem;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            -moz-appearance: textfield;

            @media screen and (max-width: 800px) {
                
                width: 65%;
            }
        }
        .input-phonenumber-profile:focus {
            border-color: #887ab8;
            box-shadow: 0 0 8px 0 #887ab8;
        }
        .detalle-editar {
            color: #887ab8;
            align-self: center;
            margin-left: 0.5rem;
            width: fit-content;
            @media screen and (max-width: 950px) {
                align-self: flex-end;
                margin-right: 0.5rem;
                margin-bottom: 0.3em;
            }
        }
        .detalle-editar-rojo {
            color: #ed312e;
            align-self: center;
            margin-left: 0.5rem;
            width: fit-content;
            @media screen and (max-width: 950px) {
                align-self: flex-end;
                margin-right: 0.5rem;
                margin-bottom: 0.3em;
            }
        }

        @media screen and (max-width: 950px) {
            > span {
                flex-direction: column-reverse;
                align-items: flex-end;
                text-align: end;
            }
        }
    }
    .info-perfil {
        width: fit-content;
        margin-left: 37.5%;
        margin-bottom: 1.5rem;

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

        @media screen and (max-width: 1100px) {
            margin-left: 40%;
        }

        @media screen and (max-width: 900px) {
            margin-left: 45%;
        }

        @media screen and (max-width: 800px) {
            margin-left: 50%;
            font-size: 12px;
        }
        @media screen and (max-width: 620px) {
            margin-left: 45%;
        }

        @media screen and (max-width: 550px) {
            margin-left: 50%;
        }
        @media screen and (max-width: 450px) {
            margin-left: 38%;
            font-size: 13px;
        }
        @media screen and (max-width: 390px) {
            margin-left: 41%;
        }
        @media screen and (max-width: 375px) {
            margin-left: 45%;
        }

        @media screen and (max-width: 375px) {
            margin-left: 46%;
            font-size: 3vw;
        }

        .nombre {
            font-size: 1.5rem;
            font-weight: 550;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            @media screen and (max-width: 800px) {
                margin-top: 0rem;
            }
            @media screen and (min-width: 1800px) {
                font-size: 1.2vw;
            }
        }
        .detalle {
            color: #887ab8;
            align-self: center;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .boton-cambiar-contraseña {
            border-radius:2em;
            padding: 0.3em 1.5em;
            background-color: $secundario;
            color: white;
            margin-left: 1em;
            border: none;
            outline: none;
            cursor: pointer;
            align-self: flex-start;
            font-size: 0.8em;
            width: fit-content;
        }
        .boton-editar-metodo {
            border-radius:2em;
            padding: 0.3em 1.5em;
            background-color: $secundario;
            color: white;
            border: none;
            outline: none;
            cursor: pointer;
            align-self: flex-start;
            font-size: 0.8em;
            width: fit-content;
        }
        .boton-cambiar-contraseña{
            border-radius:2em;
            margin-top: 1em;
            padding: 0.3em 1.5em;
            background-color: $terciario;
            color: white;
            border: none;
            outline: none;
            margin-left: 0;
            cursor: pointer;
            font-size: 0.8em;
            width: fit-content;
        }
        @media screen and (max-width: 800px) {
            margin-top: 1.5rem;
            float: none;
            justify-content: center;
        }
    }
    .label-tours-booked {
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        color: white;
        border: none;
        border-bottom-right-radius: 50px;
        background-color: #887ab8;
        width: 30%;
        align-content: flex-end;
        padding-top: 1rem;
        padding-left: 3em;
        outline: none;
        padding-bottom: 1rem;
        @media screen and (max-width: 800px) {
            width: 75%;
            font-size: 1rem;
        }
    }

    .mensaje-tours-vacios {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: fit-content;
        max-width: 90%;
        margin: 50px auto;
        font-size: 16px;
    
        h1 {
            color: $secundario;
            font-size: 1.5625em;
        }
    
        button {
            background-color: $terciario;
            color: white;
            font-size: 1.25em;
            border-radius: 25px;
            padding: 0.625em 1.25em;
            width: fit-content;
            border: none;
            margin: auto;
            margin-top: 1.875em;
            cursor: pointer;
        }
    
        @media (max-width: 765px) {
            font-size: 12px;
        }
    }
}
