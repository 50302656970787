.contenedor-tour-makers
    width: 100%
    background-color: $tour-makers
    padding: 4em 0
    padding-bottom: 7em
    transform: translateY(50px)
    position: relative
    z-index: 1

    .texto-tour-maker
        width: 33%
        padding-right: 7%
        height: 14em
        margin-top: 2em

        .titulo-tourmakers
            font-size: 1.8em

        h2
            text-align: left
            font: normal normal bold 60px/60px Ubuntu
            letter-spacing: 6px
            line-height: 1
            text-transform: uppercase
            font-size: 2.3em
            font-weight: 500
            margin-bottom: 0.5em

        .texto-tarjeta
            font-size: 1em
            font-weight: lighter
            margin-bottom: 1.4em

        button
            font-size: 1em
            text-transform: uppercase
            cursor: pointer

    .imagen-tour-makers
        width: 40%
        height: 16em
        object-fit: cover
        border-radius: 1.7em
        border-top-left-radius: 0.2em

    @media screen and ( max-width: 800px )
        flex-direction: column-reverse

        .texto-tour-maker
            width: 90%
            padding: 0
            height: fit-content
            align-items: center

            h2
                text-align: center

            .texto-tarjeta
                text-align: center

            button
                font-size: 1em

        .imagen-tour-makers
            width: 80%
            height: 12em
            margin-top: 1em