.contenedor-tarjeta-logro
    font-size: 1em
    display: flex
    flex-direction: column
    position: relative
    align-items: center
    width: 20em
    >.contenedor-imagen-logro
        position: absolute
        border: 0.45em solid $azul-claro
        +border-radius(60px)
        width: 6.5em
        height: 6.5em
        font-size: 1em
        background-color: white
        background-size: contain
        background-repeat: no-repeat
        background-position: center
    >.contenedor-texto-tarjeta-logro
        background-color: $secundario
        +border-radius(2em)
        margin-top: 4.5em
        font-size: 1em
        display: flex
        flex-direction: column
        align-items: center
        height: 20em
        max-height: 20em
        >.titulo-tarjeta-logro
            margin-top: 3em
            color: white
            font-size: 1.5em
            letter-spacing: 2px
            width: 80%
            text-align: center
            height: 3em
        >.texto-tarjeta-logro
            font-size: 1em
            margin: 0 1.5em 4em
            color: rgba(255, 255, 255, 0.8)
            text-align: center
            line-height: 1.5em
    >.triangulo-inferior-logro
        border: 1.5em solid transparent
        border-top: 1em solid $secundario
        background-color: transparent
