.tarjeta-metodo {
    margin: 0 0 1em 0;
    align-items: center;
    justify-content: center;
    .circulo-select {
        height: 1.5vw;
        width: 1.5vw;
        box-sizing: border-box;
        border: 4px solid #58c7de;
        border-radius: 6.5vw;
        cursor: pointer;
        position: relative;
        margin-right: 8vw;
        .selected {
            cursor: pointer;
            position: absolute;
            top: 7.5%;
            left: 7.5%;
            width: 80%;
            height: 80%;
            border-radius: 50%;
            background-color: #ed312e;
        }
    }
    .img {
        width: 5em;
        
    }
    .nombre-titular {
        font-size: 1em;
    }
    .icono-basura {
        font-size: 2.3vw;
        align-self: center;
        cursor: pointer;
        padding: 0;
        width: 0.9em;
    }
}
