.contenedor-dialogo-pasarela-aprobado
    font-size: 16px
    width: 75vw
    max-width: 52em
    background-color: white
    z-index: 10000
    margin: auto
    border-radius: 2.5em
    border-top-left-radius: 0.1em
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.5)
    @media screen and ( max-width: 500px )
        font-size: 3.2vw
        
    >.elementos-dialogo-aprobado
        display: grid
        font-size: 1em
        width: 50%
        box-sizing: border-box
        padding: 5.5vw 4.5vw 5.5vw 3.5vw
        row-gap: 1vw
        @media screen and ( max-width: 1000px )
            width: 100%
            padding: 3em

        >.titulo-dialogo-aprobado
            margin-bottom: 1em
            font-size: 1.5em

        >.texto-dialogo-aprobado
            font-size: 0.95em

        >.margen-elemento-dialogo-aprobado
            margin-top: 6vw
            margin-bottom: 1em

        >.boton-dialogo-aprobado
            font-size: 1.2em
            border: none
            width: 13em
            justify-self: center
            padding: 0.7em 0
            border-radius: 0.8em
            border-top-left-radius: 0.2em
            cursor: pointer
            text-transform: uppercase
            letter-spacing: 3px
            margin-left: 0.5em
            text-decoration: none
            text-align: center

    >.imagen-pago-aprobado
        font-size: 1em
        width: 50%
        background-repeat: no-repeat
        background-size: cover
        border-radius: 0
        border-bottom-right-radius: 2.5em
        border-top-right-radius: 2.5em
        @media screen and ( max-width: 1000px )
            display: none
