.contenedor-dialogo-calificar
    font-size: 16px
    display: flex
    flex-direction: column
    align-items: center
    margin: auto
    width: 38em
    background: #FFFFFF 0% 0% no-repeat padding-box
    min-height: 20em
    @include box-shadow(0px 5px 20px #2A244DCE)
    padding: 2.2em 2em 2em
    z-index: 1302
    border-radius: 0.2em 3em 3em 3em !important
    @media screen and ( max-width: 800px )
        width: 75vw
    @media screen and ( max-width: 500px )
        font-size: 3.2vw

    >.titulo-dialogo
        text-align: center
        font-size: 1.4em
        font-weight: bolder
        color: $terciario
        margin-bottom: 0.8em
        text-transform: uppercase
        letter-spacing: 2px

    >.descripcion-dialogo
        text-align: center
        font-size: 1em
        color: $primario
        width: 70%
        margin: auto
        margin: 1em 0 2em
        letter-spacing: 2px
        @media screen and ( max-width: 800px )
            width: 23em

    >.formulario-comentario-calificar
        display: flex
        width: 100%
        justify-content: space-evenly
        font-size: 1em
        flex-direction: column
        align-items: center

        >.boton-dialogo
            padding: 0.5em 0
            background-color: $secundario
            border-radius: 1.2em !important
            color: white
            border: none
            font-weight: bold
            outline: none
            cursor: pointer !important
            font-size: 1.2em
            width: 6em
            text-transform: uppercase
            letter-spacing: 2px

        >.textarea-comentario-calificar
            resize: none
            background: linear-gradient(to bottom, rgba(179, 104, 169, 0.4), rgba(136, 122, 184, 0.4))
            width: 95%
            height: 4em
            box-sizing: border-box
            +border-radius(1em 0.2em 1em 1em)
            padding: 1em 1em 1em 2em
            border: none
            font-size: 1.1em
            color: $primario
            // font-weight: bold
            letter-spacing: 1px
            cursor: text
            &::placeholder
                color: $primario

        >.error-dialogo-calificar
            min-height: 2em
            margin: 0.5em 0
