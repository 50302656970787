.contenedor-mapa-lista
    width: 100%
    margin: 2em 0

.contenedor-lugares
    width: 50%

    .contenedor-textos
        width: 80%
        padding: 0 5% 0 15%
        
        div
            margin: 2em 0 0.7em
            
        p
            letter-spacing: 2px
            font-weight: lighter
            line-height: 1.5

    .contenedor-place
        width: 80%
        margin: 0 5% 0 15%
        margin-bottom: 2em
        padding: 0.5em 0.3em 1.5em
        border-bottom: solid 0.15em $primario
        cursor: pointer
        transition: 0.5s all ease-in-out

        .imagen-calificacion
            width: 48%
            height: 10em
            overflow: hidden
            border-radius: 0.6em
            border-top-left-radius: 0.2em
            position: relative

            img
                width: 100%
                height: 100%
                object-fit: cover
            
            .filtro-morado
                width: 96%
                height: 96%
                padding: 2%
                position: absolute
                top: 0
                left: 0
                background: linear-gradient( to bottom ,rgba(0, 0, 0, 0) 40%,rgba(44,34,80, 0.8))

                img
                    width: 2em
                    height: 2em
                    object-fit: contain

                .contenedor-estrellas-tour
                    .MuiRating-root
                        opacity: 1
            
        .contenedor-informacion-place
            width: 48%
            height: 10em

            .boton-place
                background-color: $secundario
                text-decoration: none
                padding: 0.4em 1em
                border-radius: 0.8em
                border-top-left-radius: 0.2em
                width: fit-content
                letter-spacing: 2.1px
                opacity: 1
                font-size: 0.9em

            p
                width: 100%
                overflow-wrap: break-word
                max-height: 6.7em
                overflow: hidden
                text-align: justify

            .con-putnos-suspensivos
                position: relative

                &::after
                    content: '...'
                    position: absolute
                    bottom: 0
                    right: 0
                    background: white
                    display: inline
            

    .lugar-seleccionado
        background-color: rgb(224, 224, 224)
        transition: 0.5s all ease-in-out

    @media screen and ( max-width: 850px )       
        width: 100%

        .contenedor-place
            width: 90%
            margin: 0 5%
            margin-bottom: 2em
            flex-direction: column
            // padding: 0.5em 0.3em 1.5em
            .imagen-calificacion
                width: 100%
            
            .contenedor-informacion-place
                width: 100%