.contenedor-filtro-classes
    width: 100%
    margin-bottom: 3em

    .carrusel-classes
        display: flex
        align-items: center
        max-width: 95%
        margin-bottom: 2em

        .arrow
            border: solid #ED312E
            border-width: 0 5px 5px 0
            display: inline-block
            padding: 6px
            cursor: pointer

        .arrow-left
            transform: rotate(135deg)
            -webkit-transform: rotate(135deg)

        .arrow-right
            transform: rotate(-45deg)
            -webkit-transform: rotate(-45deg)
            
    .contenedor-lista-class
        overflow-x: hidden
        max-width: 73.5em
        font-size: 16px
        margin: 0 2em

        .lista-class
            transition: all ease 0.5
            display: flex
            align-items: center
            transition: all ease 1s

        .contenedor-class
            margin: 0 1em
            display: grid
            grid-template-columns: 1fr  
            grid-template-rows: 3fr 1fr
            justify-items: center
            cursor: pointer
            min-width: 8em
            width: 8em
            height: 9em
            padding: 0.4em 0.25em 
            background-color: #D7C5DF
            border-radius: 5px 20px 20px 20px
            box-shadow: 0px 3px 6px #00000029

            &:hover
                background-color: $primario
            
            .imagen-clase
                background-color: white
                width: 95%
                height: 5em
                display: grid
                place-items: center
                border-radius: 5px 20px 20px 20px
                padding: 0.5em 0
                margin-bottom: 0.5em

                img
                    border-radius: 5px 10px 10px 10px
                    width: 60%
                    object-fit: cover

            .titulo-clase
                text-align: center
                color: white
                font-weight: bold
                letter-spacing: 0.05em
                text-transform: uppercase
                word-break: break-word
                font-size: 15px

        .contenedor-class-selected
            background-color: $primario

    .texto-mensaje-layers
        color: $terciario
        text-align: center
        width: 80%
        letter-spacing: 0.10em
        margin-bottom: 2em

    .lista-layers-categories
        width: 75%
        flex-wrap: wrap
        min-width: 28.125em

        .layer-category-button
            margin: 0.5em 1em
            cursor: pointer
            font-weight: bold
            padding: 0.75em 1.25em 
            border-radius: 2.375em
            font-size: 0.8em
            letter-spacing: 0.04em

            &:hover
                color: white

        .layer-button
            border: 3px solid $secundario
            color: $secundario
            text-align: center
            user-select: none


            &:hover
                background-color: $secundario

        .layer-button-selected
            color: white
            background-color: $secundario

        .category-button
            border: 3px solid $primario
            color: $primario
            text-align: center
            user-select: none
            
            &:hover
                background-color: $primario

        .category-button-selected
            color: white
            background-color: $primario

    @media screen and (max-width: 500px)
        .lista-layers-categories
            font-size: 3vw

                    



