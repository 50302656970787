.features-general
    font-size: 1em
    width: 100%
    margin-bottom: 10vw
    @media screen and ( max-width: 1200px )
        font-size: 1.32vw
    @media screen and ( max-width: 800px )
        font-size: 2vw
    >.contenedor-features
        display: flex
        font-size: 1em
        width: 100%
        column-gap: 2vw
        margin-left: 3em
        @media screen and ( max-width: 800px )
            flex-direction: column
            margin-left: 0
            
        >.contenedor-celular-features
            font-size: 1em
            +border-top-right-radius(75px)
            width: 35%
            display: flex
            flex-direction: column
            align-items: center
            // padding-left: 2.5em
            @media screen and ( max-width: 800px )
                width: 100%
                padding-left: 0
                margin: 2em 0 3em

            >.contenedor-imagenes-celulares-features
                position: relative
                height: 33em
                margin-top: 3em
                @media screen and ( max-width: 800px )
                    height: 20em

                >.celular-features
                    width: 22em
                    left: 50%
                    transform: translate(-50%, 0)
                    position: absolute
                    transition: all 1s ease
                    @media screen and ( max-width: 800px )
                        width: 15em
                    
            >.logo-features
                width: 12em
                margin: 1em 0 3em

        >.contenedor-tarjetas-features
            font-size: 1em
            width: 60%
            display: flex
            flex-direction: column
            align-items: center
            @media screen and ( max-width: 800px )
                width: 100%

            >.grilla-tarjetas-features
                font-size: 1em
                box-sizing: border-box
                display: grid
                grid-template-columns: repeat(auto-fill,minmax(21em,1fr))
                grid-template-rows: repeat(2,11em)
                row-gap: 7vw
                margin: 8vw 0 0em 0em
                width: 95%
                justify-items: center
                @import ./tarjeta-feature
                @media screen and ( max-width: 800px )
                    margin-bottom: 0em
                    margin-top: 3em
                    width: 90%
                    grid-template-rows: none

