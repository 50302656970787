.opciones-aplicacion
    width: 100%
    padding: 2em 0%
    margin: 2em 0 1em 0

    .tarjeta-opcion
        width: 28em
        height: 14em
        overflow: hidden
        +border-radius(1.6em)
        +border-top-left-radius(0.3em)
        position: relative
        margin: 0.5em 2em 0.5em 3em
        padding-right: 1em
        font-size: 1.1em

        .tarjetas-tours-places
            width: 92.8%
            height: 90%
            margin: 2.5% 5%
            +border-radius(2em)
            +border-top-left-radius(0.3em)
            overflow: hidden
            transition: 0.6s all ease-out

            img
                width: 100%
                height: 100%
                object-fit: cover

        .ilustracion-opcion
            position: absolute
            top: 0
            width: 100%
            height: 100%
            padding: 0
            left: 0.8em
            transition: 0.6s all ease-out

            img
                width: 41%
                margin-bottom: 0.5em
                transition: 0.6s all ease-out
                margin-right: 1.2em
                position: relative
                z-index: 10

        .contenedor-textos
            position: absolute
            width: calc( 83% - 1em )
            height: 70%
            padding: 5%
            top: 5%
            left: 5%
            background-color: $morado-oscuro
            +border-radius(2em)
            +border-top-left-radius(0.3em)
            overflow: hidden
            transition: 0.6s all ease-out

            .titulo-tarjeta
                margin: 0.3em 1em 0
                color: $terciario
                font-size: 1em
                width: 14em

                .boton-mas
                    font-size: 2em
                    margin-right: 1em
                    font-weight: bold
                    
            .texto-tarjetas
                font-size: 0.8em
                width: 15em
                margin: 0.8em 1.4em

        .boton-tarjeta
            font-size: 1em
            width: 65%
            position: relative
            z-index: 10
            margin: 0em 0.5em

        &:hover

            .tarjetas-tours-places
                width: 100%
                height: 100%
                margin: 0

            .contenedor-textos
                background: rgba(44,34,80, 0.35)
                width: calc( 90% - 1em )
                height: 80%
                top: 0
                left: 0
                margin: 0
                margin-right: 1em

                .titulo-tarjeta
                    color: white

            .ilustracion-opcion
                width: calc( 100% - 1em )

                img
                    width: 41%
                    margin-bottom: -0.1em
                    margin-right: 0.1em

    @media screen and ( min-width: 1800px )
        font-size: 1.7em

    @media screen and ( max-width: 1500px )
        font-size: 1em

    @media screen and ( max-width: 1130px )
        font-size: 0.9em

    @media screen and ( max-width: 1024px )
        font-size: 1.3vw

    @media screen and ( max-width: 800px )
        flex-direction: column
        font-size: 2vw
        justify-content: center

    @media screen and ( max-width: 425px )
        font-size: 2.8vw

    @media screen and ( max-width: 320px )
        font-size: 2.9vw


.boton-tarjeta
    background: none
    background-color: white
    border-radius: 1em
    border: none
    padding: 0.5em 1.3em
    font-size: 0.7em
    font-weight: 500
    margin: 0 0 2em
    text-decoration: none