.contenedor-logros
    font-size: 1em
    display: flex
    flex-direction: column
    width: 100%
    margin: 4em 0 6em
    @media screen and ( max-width: 800px )
        font-size: 2vw
    >.titulo-logros
        color: $terciario
        font-weight: bold
        text-transform: uppercase
        font-size: 2em
        letter-spacing: 2px
        text-align: center
        width: 100%
        @media screen and ( max-width: 800px )
            width: 90%
            text-align: center
            align-self: center
            
    >.grilla-tarjetas-logros
        margin: 3em 0 0
        margin-top: 3em
        display: flex
        flex-wrap: wrap
        justify-content: space-around
        width: 97%
        justify-items: center
        align-self: center

        .contenedor-tarjeta-logro
            margin: 0 20px
            
        @import ./tarjeta-logro
        @media screen and ( max-width: 1334px )
            margin-left: 0
            