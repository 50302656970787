.contenedor-tarjeta-feature
    font-size: 1em
    +border-radius(3em)
    +border-top-left-radius(0.3em)
    background-color: $primario
    display: flex
    width: 25vw
    max-width: 23em
    min-width: 20em
    height: 12em
    box-sizing: border-box
    @media screen and ( max-width: 600px )
        height: 35vw
    >.contenedor-imagen-feature
        width: 40%
        text-align: center
        >img
            width: 7em
    >.contenedor-texto-tarjeta-feature
        padding-top: 1em
        font-size: 0.9em
        margin: 2em 2em 2em 0em
        display: flex
        flex-direction: column
        width: 60%
        box-sizing: border-box
        @media screen and ( max-width: 600px )
            font-size: 1.2em
        >.titulo-tarjeta-feature
            letter-spacing: 2px
            color: white
            margin-bottom: 0.5em
        >.texto-tarjeta-feature
            color: rgba(255, 255, 255, 0.9)
            font-size: 1em
            font-weight: 200
            letter-spacing: 2px