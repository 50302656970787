.contenedor-dias
    margin-left: 0.7em

    .dia-horario
        margin: 0 0.5em
        +border-radius(1em)
        padding: 0.15em 0.5em
        font-size: 0.8em
        cursor: pointer

    .contenedor-horario
        margin: 0.5em 0.8em

    
    .borde-primario
        border: solid 1px $primario 

    .borde-blanco
        border: solid 1px white 


.contenedor-horarios
    @media screen and ( max-width: 850px )
        flex-direction: column
        font-size: 1.6em

        h4
            margin-bottom: 1em

        .contenedor-dias
            margin: 0
            //font-size: 1.5em

            .contenedor-formas-dias
                flex-wrap: wrap
                justify-content: center

                .dia-horario
                    margin-bottom: 1em

