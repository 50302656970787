.tours-booked {
    margin-block: 3rem;
    width: 100%;
    @media screen and (min-width: 1800px) {
        font-size: 1.2vw;
    }
    @media screen and (max-width: 800px) {
        font-size: 1em;
    }
    .arrow {
        border: solid $secundario;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 7px;
        cursor: pointer;
        z-index: 30;
        @media screen and (max-width: 800px) {
            padding: 8px;
        }
        @media screen and (max-width: 620px) {
            display: none;
        }
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        @media screen and (max-width: 800px) {
            margin-right: 0.6rem;
        }
    }

    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        @media screen and (max-width: 800px) {
            margin-left: 0.6rem;
        }
    }

    .contenedor-tarjetas-booked {
        height: fit-content;
        padding: 1em 0;
        overflow-x: hidden;

        .div-margin-tours-booked {
            display: flex;
            overflow: auto;
            flex: none;
            padding: 0;
            margin: 0;
            &::-webkit-scrollbar {
                display: none;
            }

            .contenedor-con-fecha {
                width: 23vw;
                min-width: 23vw;
                margin: 0 2vw 0;

                @media screen and (max-width: 800px) {
                    width: 38vw;
                    margin: 0 0 0 4vw;
                    @include transition(1s all ease);
                }

                @media screen and (max-width: 620px) {
                    width: 80vw;
                    margin: 0 0 0 4vw;
                    @include transition(1s all ease);
                }

                .texto-azul{
                    align-self: flex-start;
                    font-weight: 500;
                    font-size: 1.1em;
                }

                .boton-about {
                    width: 60%;
                    font-size: 0.8rem;
                    font-weight: 450;
                    color: white;
                    border: none;
                    border-radius: 2em;
                    background-color: $secundario;
                    cursor: pointer;
                    align-content: center;
                    align-self: center;
                    outline: none;
                    margin-top: 1rem;
                    margin-right: 2rem;
                    padding: 0.5em;
                    z-index: 30;
                    @media screen and (min-width: 1800px) {
                        font-size: 1vw;
                    }
                    @media screen and (max-width: 1024px) {
                        width: 80%;
                        font-size: 1.4vw;
                    }
                    @media screen and (max-width: 800px) {
                        width: 65%;
                        font-size: 1.8vw;
                    }
                    
                    @media screen and (max-width: 620px) {
                        font-size: 2.5vw;
                    }

                    @media screen and (max-width: 425px) {
                        font-size: 3vw;
                    }
                }

                .tarjeta-tour-booked {
                    cursor: pointer;
                    width: 23vw;
                    height: 18em;
                    overflow: hidden;
                    margin-right: 2.5em;
                    margin-left: 0.75em;
                    border-radius: 2em;
                    border-top-left-radius: 0.3em;
                    position: relative;

                    .imagen-tours-booked {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    @media screen and (max-width: 1024px) {
                        width: 23vw;
                        height: 18em;
                    }

                    @media screen and (max-width: 800px) {
                        width: 34vw;
                        height: 18em;
                    }

                    @media screen and (max-width: 620px) {
                        width: 78vw;
                        height: 18em;
                    }

                    .contenedor-textos-tour-booked {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, $morado-oscuro 100%);
                        cursor: pointer;
                        
                        .contenedor-imagen-booked {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin-top: 1em;
                            margin-right: 1em;
                            height: 2.7em;
                            width: 2.7em;
                            object-fit: cover;
                            box-sizing: border-box;
                            border: 2px solid #eee1f4;
                            border-radius: 50%;
                            background-position: center;
                            background-size: cover;
                        }

                        .boton-mas-tour-booked {
                            font-size: 2em;
                            margin-right: 1em;
                            font-weight: bold;
                        }

                        .titulo-tarjeta {
                            width: 85%;
                            margin: 0.7em 0;
                            font-size: 1.3em;
                        }
                    }
                }
            }

            .skeleton-booked{
                height: 18em;
            }

        }

        .div-margin-tours-booked > div{
            text-align: center;
            scroll-snap-align: start;
            flex: none;
        }

        .x-booked {
            width: 87vw;
            height: 100%;
            flex-flow: row nowrap;
        }

        .scroll-booked{
            scroll-snap-type: x mandatory;
        }
    }

    .padding-left{
        padding-left: 2vw;
    }
}

.contenedor-error{
    background-color: $morado-carrusel;
    width: 16vw;
    height: 9vw;
    margin: 4vw;
    padding: 4vw;
    border-radius: 3vw;
    @media screen and (min-width: 1800px) {
        font-size: 1.5em;
    }
    @media screen and (max-width: 800px) {
        font-size: 0.8em;
    }
}
