$primario: #887AB8
$secundario: #6DACD8
$terciario: #ED312E
$morado-oscuro: rgb(44,34,80)
$morado-claro: #B368A9
$azul-claro: #b4cef0
$linea-pregunta: #EEE1F4
$rosado-claro:  #E0C2DB
$morado-carrusel: #D0C9E0

$tour-makers: #191B27

$morado-oscuro-transparente: rgb(44,34,80, 0.5)