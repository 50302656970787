.contenedor-layer-tour

    .contenedor-info-mapa-layer

        .contenedor-info
            width: 47%
            @import ./components/listaAudios/lista-audios

            .contenedor-info-tour
                width: 100%

                .titulo-info-tour
                    width: 100%

                .texto-info-tour
                    width: 100%
                    font-weight: lighter

        &> .contenedor-mapa-tour
            width: 47%

            .map-container
                width: 100%
                position: relative
                max-height: 50vh
                min-height: 20em

    @import ./components/horarios/horarios


    @media screen and ( max-width: 800px )
        flex-direction: column
        
        .contenedor-info-mapa-layer
            .contenedor-info
                width: 100%

                .titulo-info-tour
                    margin-bottom: 0
                    text-align: center

                .texto-info-tour
                    text-align: center
                    margin-bottom: 0
                    




@import ./components/galeria/galeria