.seccion-tours-places
    display: flex
    flex-direction: column
    align-items: center

    @import ./components/listaCiudades/lista-ciudades

    .contenedor-botones
        margin: auto
        margin-top: 7em
        width: 40em

        .btn-selected
            background-color: $primario
            @include border-radius(5px 20px 20px 20px)

        .btn-no-selected
            background-color: white
            border: 3px solid $primario
            color: $primario
            @include border-radius(20px 20px 5px 20px)

        >button
            letter-spacing: 0.1em
            cursor: pointer
            padding: 1.5em 7em
            color: white
            border: none
            font-weight: bold
            min-width: fit-content
            width: 22em

    @media screen and (max-width: 700px)
        .contenedor-botones
            flex-direction: column
            height: 8em
            width: 100%
            margin-top: 3em

    @import ./components/filtros/filtros

    .tours-container
        width: 100%
        display: flex
        flex-direction: column
        align-items: center

        @import ./components/botonOrdenar/boton-ordenar

        .ordenar
            margin-top: 1.5em
            margin-right: 6vw
            align-self: flex-start
            align-self: flex-end

        .lista-tours
            margin: 5em 0em
            display: grid
            max-width: 95%
            row-gap: 3em
            column-gap: 2em
            grid-template-columns: repeat(auto-fill, minmax(17em, 1fr))
            justify-items: center
            width: 1200px

        @import ./../general/authorizing/components/toursLiked/components/tarjetaTourLiked/tarjeta-tours-liked.scss

        @media screen and (max-width: 650px)
            .lista-tours
                font-size: 2.5vw
        
        .boton-cargar-tours
            outline: none
            +border-radius(2em)
            font-family: Ubuntu
            border: none
            font-size: 0.8em
            padding: 0.8em 3em
            letter-spacing: 1px
            cursor: pointer

        .mensaje-sin-tours
            display: flex
            flex-direction: column
            text-align: center
            width: fit-content
            max-width: 90%
            margin: 100px auto
            font-size: 16px
            color: $secundario
            font-size: 1.5625em
    
    .titulo-places
        color: $secundario
        font-weight: bold
        font-size: 2em
        text-align: center
        width: 80%
        margin-bottom: 1em

    .contenedor-sitios-lista
        position: relative
        width: 100%

            

        @import ./components/mapa/map
        
        @media screen and ( max-width: 850px )
            flex-direction: column-reverse

            .map-container
                width: 90%
                height: 18em
                margin: 0 5% 2em
                position: relative
                +border-radius(1em)
                overflow: hidden

        @import ./components/listaLugares/listaLugares

    @import ./components/filtroClasses/filtro-classes