.titular
    display: flex
    width: 100%
    @media screen and (max-width:800px)
        flex-direction: column
    .linea-vertical
        border-left: 1.5px solid #887AB8
        background-color: white
        margin: 0rem 0.3rem
    .card-data
        width: 50%
        @media screen and (max-width:800px)
            width: 100%
        .titulo-azul
            margin-top: 1em
            font-weight: 550
            font-size: 1em
            @media screen and (max-width:800px)
                font-size: 3.5vw
        .select
            outline: none
            //box-sizing: border-box
            transition: .3s
            margin-bottom: 1em
            font-size: 0.7rem
            color: black
            width: 100%
            height: 1.7rem
            min-height: 1em
            border-top-right-radius: 1px
            border-top-left-radius: 10px
            border-bottom-left-radius: 10px
            border-top-right-radius: 2px
            border-bottom-right-radius: 10px
            background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
            border: none
            outline: none
            transition: .3s

            @media screen and (max-width:800px)
                font-size: 0.8rem
            
            .css-h9j160-ValueContainer 
                margin-bottom: auto
            // @media screen and (max-width:800px)
            //     cursor: pointer
            //     width: 100%

        .tarjeta
            justify-content: space-between
            padding-left: 1.5em
            padding-right: 1.5em
            margin-bottom: 1.2em
            margin-top: 2em
            @media screen and (max-width: 800px)
                margin-bottom: 0.5rem
                margin-top: 1.5em
            @media screen and (max-width:1200px)
                margin-bottom: 1.9rem
            .img-tarjeta
                width: 4.3em
                height: 2.5em

                @media screen and (max-width: 1200px)
                    width: 2em
                @media screen and (max-width: 800px)
                    width: 7em
                @media screen and (max-width: 600px)
                    width: 4em
                @media screen and (max-width: 540px)
                    width: 4em
                @media screen and (max-width: 400px)
                    width: 3em
                @media screen and (max-width: 320px)
                    width: 2em

            .img-tarjeta-focused
                width: 3.5vw
                cursor: pointer
                border-color: $secundario
                outline: 3px solid $secundario
                border-radius: 1.5rem

                box-sizing: border-box
        .titular-tarjeta
            display: flex
            flex-direction: column
            padding-left: 1.5em
            padding-right: 1.5em
            margin-top: 0.5em
            @media screen and (max-width:800px)
                margin-top: 0.7em
                padding: 0
            .titular-label
                padding-left: 0.6rem
                color: #887AB8
                margin-bottom: 0.7rem
                font-size: 0.9em
                font-weight: 350
                @media screen and (max-width:800px)
                    font-size: 3.3vw
            .ccv
                padding-left: 0.6rem
                color: $terciario
                margin-bottom: 0.7rem
                font-size: 0.8em
                align-self: flex-end
                margin-right: 17%
                font-weight: 350
                @media screen and (max-width:800px)
                    font-size: 3.3vw
            .input-fecha
                width: 40%
                height: 1.7rem
                color: white
                border-top-left-radius: 10px
                border-top-right-radius: 1px
                border-bottom-left-radius: 10px
                margin-right: 1em
                border-bottom-right-radius: 10px
                border-top-right-radius: 2px
                padding: 0.5rem 0.8rem
                background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                border: none
                outline: none
                box-sizing: border-box
                transition: .3s
                color: #F0F3F4
                align-self: flex-end
                font-size: 1em
                margin-bottom: 0.7rem
            .input-fecha:focus
                border-color: #887AB8
                box-shadow: 0 0 8px 0 #887AB8
            .input-fecha::placeholder
                color: #F0F3F4
            .input-ccv-tarjeta
                width: 40%
                height: 1.7rem
                border-top-left-radius: 10px
                border-top-right-radius: 1px
                border-bottom-left-radius: 10px
                border-bottom-right-radius: 10px
                border-top-right-radius: 2px
                padding: 0.5rem 0.8rem
                background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                border: none
                color: #F0F3F4
                outline: none
                box-sizing: border-box
                transition: .3s
                align-self: flex-end
                font-size: 1em
                margin-bottom: 0.7rem
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button
                    -webkit-appearance: none
                    margin: 0
                &[type=number]
                    -moz-appearance: textfield
                
            .input-ccv-tarjeta:focus
                border-color: #887AB8
                box-shadow: 0 0 8px 0 #887AB8
            .input-titular-tarjeta
                width: 100%
                height: 1.7rem
                border-top-left-radius: 10px
                border-bottom-left-radius: 10px
                border-bottom-right-radius: 10px
                border-top-right-radius: 2px
                padding: 0.5rem 0.8rem
                background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                border: none
                color: #F0F3F4
                outline: none
                box-sizing: border-box
                transition: .3s
                font-size: 20px
                margin-bottom: 0.7rem
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button
                    -webkit-appearance: none
                    margin: 0
                &[type=number]
                    -moz-appearance: textfield
                
                @media screen and (max-width:800px)
                    padding: 0.2rem 0.5rem
                    height: fit-content
                    border-top-left-radius: 0.3rem
                    border-bottom-left-radius: 0.3rem
                    border-bottom-right-radius: 0.3rem
                    border-top-right-radius: 1px
            .input-titular-tarjeta:focus
                border-color: #887AB8
                box-shadow: 0 0 8px 0 #887AB8
        .celular
            padding-left: 1.5em
            padding-right: 1.5em
            margin-top: 1rem
            justify-content: space-between
            .label-celular
                padding-left: 0.3rem
                margin-top: 0.5rem
                font-size: 1em
                font-weight: 350
                color: #887AB8
            .input-celular
                width: 75%
                height: 1.7rem
                color: #7B7D7D
                border-top-left-radius: 10px
                border-bottom-left-radius: 10px
                border-top-right-radius: 2px
                border-bottom-right-radius: 10px
                border-top-right-radius: 1px
                padding: 0.5rem 0.8rem
                color: white
                background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                border: none
                outline: none
                box-sizing: border-box
                transition: .3s
                font-size: 17px
                margin-bottom: 1rem
            .input-celular:focus
                border-color: #887AB8
                box-shadow: 0 0 8px 0 #887AB8
.error
    margin-top: 2rem
    margin-bottom: 1rem
    font-size: 1rem
    text-align: center
