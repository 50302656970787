.carrusel-opiniones
    width: 100%
    overflow: hidden

    @media screen and ( max-width: 1500px )
        font-size: 1em

    @media screen and ( min-width: 1800px )
        font-size: 1.2vw
    
    .contenedor-texto-carrusel
        width: 27vw
        min-width: 27vw
        height: 13em
        margin: 5vw 5.3vw
        border-radius: 0.23em 2.3em 2.3em 2.3em
        padding: 1.5%
        background: linear-gradient(to bottom, $rosado-claro, $morado-carrusel)
        color: $primario

        h2
            font-size: 1.7em

        .textos
            font-size: 0.75em
            margin: 4em 4%

        .personas
            width: 5em
            min-width: 5em
            height: 5em
            border-radius: 100%
            margin: 3em 3%
            border: solid 0.2em white
            object-fit: cover

        @media screen and ( max-width: 800px )
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: center
            width: 70vw
            min-width: 70vw
            height: 18em
            margin: 1.5em 2.3vw 1.5em 5.1vw

            .personas
                width: 5em
                min-width: 5em
                height: 5em
                border-radius: 100%
                margin: 0.5em 0% 1em 0%

            .textos
                font-size: 0.8em
                margin: 0em 4% 0.8em 4%
                text-align: center

    .boton-izquierda
        margin-top: 7.5em
        left: 4.5%
        position: absolute
        z-index: 2
        transform: rotate(90deg)

        button
            border: none
            background: none
            cursor: pointer

            img
                width: 2em
                height: 2em
                margin: 1em

        @media screen and ( max-width: 800px )
            margin-top: 8em
            left: 1%

            button

                img
                    width: 1.5em
                    height: 1.5em
                    margin: 0.5em

        @media screen and ( min-width: 1800px )
            button
                img
                    font-size: 0.8vw
            
    .boton-derecha
        display: flex
        justify-content: flex-end
        align-items: flex-end
        margin-top: 7.5em
        right: 4.5%
        position: absolute
        z-index: 2
        transform: rotate(-90deg)

        button
            border: none
            background: none
            cursor: pointer

            img
                width: 2em
                height: 2em
                margin: 1em

        @media screen and ( max-width: 800px )
            margin-top: 8em 
            right: 1%
            button

                img
                    width: 1.5em
                    height: 1.5em
                    margin: 0.5em

        @media screen and ( min-width: 1800px )
            button
                img
                    font-size: 0.8vw

    .contenedor-ocultar
        width: 80vw
        margin: 0 10%
        overflow: hidden

        @media screen and ( max-width: 800px )
            width: 80vw
            margin: 0 10vw


    .carrusel-textos
        transition: 1s ease-in-out
    
    @media screen and ( max-width: 800px )
        .carrusel-opiniones
            width: 80vw