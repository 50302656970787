
.dropdown
    user-select: none
    display: flex
    flex-direction: column
    position: relative

    background-color: white

    .contenedor-dropdown
        z-index: 2
        position: absolute
        background-color: white
        border-bottom-right-radius: 15px
        border-top-right-radius: 15px
        display: flex
        flex-direction: column
        width: fit-content
        margin-top: 2em
        padding: 0 1em
        +box-shadow(0px 3px 3px #00000029)
        //padding-top: calc(2.5vw + 5px)
        .boton-dropdown
            color: $primario
            font-size: 1.2vw
            padding: 1rem 0.9rem 1rem 0.5rem
            cursor: pointer
            white-space: nowrap

        .boton-dropdown:hover
            color: #ED312E
        .linea
            border-bottom: 2px solid $primario
            background-color: white
            margin: 0rem 0.3rem
@media screen and ( max-width: 800px )

    .dropdown
        position: absolute
        width: 100vw
        height: 100vh
        top: 0
        background: $primario
        right: 0
        transition: all ease 1s
        .cerrar
            position: absolute
            right: 1rem
            top: 1rem
            color: white
            font-size: 12vw
            z-index: 21
            cursor: pointer
        .contenedor-dropdown
            align-items: center
            justify-content: center
            background: $primario
            align-content: center
            align-self: center
            width: 100%
            box-shadow: none
            margin: 50% 0
            padding: 0
            .boton-dropdown
                color: white
                font-size: 2em
                padding: 1rem 0.9rem 1rem 0.5rem
                cursor: pointer
                white-space: nowrap

.fondo-dialogo-auth
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1000
    position: fixed
    -webkit-tap-highlight-color: transparent
    display: flex
    flex-direction: column
    overflow: auto
    padding: 1rem 0

    .fondo-dialogo-rc
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 1001
        position: absolute
        -webkit-tap-highlight-color: transparent
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

    .contenedor-dialogo
        display: flex
        flex-direction: column
        align-items: center
        width: 60%
        padding: 1em 1em
        background-color: white
        z-index: 1302
        margin: auto
        font-size: 1vw
        border-bottom-right-radius: 1rem
        border-bottom-left-radius: 1rem
        border-top-right-radius: 1rem
        border-top-left-radius: 0.1rem
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.5)
        @media screen and (min-width:1024px)
            width: 50%
        @media screen and (max-width:768px)
            width: 90%

        .titulo-dialogo-auth
            text-align: center
            font-size: 1.2vw
            font-weight: bolder
            color: $secundario
            margin-top: 2vw
            margin-bottom: 2vw
            @media screen and (max-width:800px)
                font-size: 3vw
        .sign-up
            display: flex
            flex-direction: column
            width: 100%
            .contenedor-botones-dialogo
                display: flex
                width: 100%
                justify-content: space-evenly
                font-size: 1em

                .boton-dialogo
                    border-top-right-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.7em 2em
                    background-color: $secundario
                    color: white
                    border: none
                    font-weight: 500
                    margin: 1em 0
                    outline: none
                    cursor: pointer
                    font-size: 1.1em
                    width: fit-content
                    @media screen and (max-width:800px)
                        font-size: 3em
            .names
                display: flex
                justify-content: space-between
                padding-left: 2vw
                padding-right: 2vw
                margin-top: 1rem
                .name-container
                    display: flex
                    width: 48%
                    flex-direction: column

                    .label-signup
                        padding-left: 0.3rem
                        font-size: 1.2vw
                        color: #887AB8
                        font-weight: 350
                        margin-bottom: 0.4rem
                        padding-left: 0.6rem
                        @media screen and (max-width:800px)
                            font-size: 3vw
                    .input-signup-names
                        width: 100%
                        border-top-left-radius: 10px
                        border-bottom-left-radius: 10px
                        border-bottom-right-radius: 10px
                        padding: 0.5rem 0.8rem
                        color: #F0F3F4
                        background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                        border: none
                        outline: none
                        box-sizing: border-box
                        transition: .3s
                        font-size: 17px
                        @media screen and (max-width:800px)
                            padding: 0.2rem 0.5rem
                            height: fit-content
                            border-top-left-radius: 0.3rem
                            border-bottom-left-radius: 0.3rem
                            border-bottom-right-radius: 0.3rem
                            border-top-right-radius: 1px

                    .input-signup-names:focus
                        border-color: #887AB8
                        box-shadow: 0 0 8px 0 #887AB8
            .email
                display: flex
                flex-direction: column
                padding-left: 2vw
                margin-top: 1rem
                padding-right: 2vw
                .label-email-signup
                    padding-left: 0.3rem
                    margin-bottom: 0.7rem
                    color: #887AB8
                    font-size: 1.2vw
                    font-weight: 350
                    padding-left: 0.6rem
                    @media screen and (max-width:800px)
                        font-size: 3vw
                        margin-bottom: 0.4rem

                .input-email-signup

                    width: 100%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    color: #F0F3F4
                    outline: none
                    box-sizing: border-box
                    transition: .3s
                    font-size: 17px
                    margin-bottom: 0.3rem
                    @media screen and (max-width:800px)
                        width: 100%
                        padding: 0.2rem 0.5rem
                        height: fit-content
                        border-top-left-radius: 0.3rem
                        border-bottom-left-radius: 0.3rem
                        border-bottom-right-radius: 0.3rem
                        border-top-right-radius: 1px
                .input-email-signup:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8
                .send
                    margin-top: 0.2rem
                    font-size: 1.2vw
                    font-weight: 350
                    color: $secundario
                    @media screen and (max-width:800px)
                        font-size: 3vw
            .password
                display: flex
                justify-content: space-between
                padding-left: 2em
                padding-right: 2em
                margin-top: 1rem
                .password-container
                    display: flex
                    width: 48%
                    flex-direction: column
                    .label-password-signup
                        padding-left: 0.3rem
                        font-size: 1.2vw
                        margin-bottom: 0.7rem
                        font-weight: 350
                        color: #887AB8
                        padding-left: 0.6rem
                        @media screen and (max-width:800px)
                            font-size: 3vw
                            margin-bottom: 0.4rem

                    .input-password-signup
                        width: 100%
                        border-top-left-radius: 10px
                        border-bottom-left-radius: 10px
                        border-bottom-right-radius: 10px
                        padding: 0.5rem 0.8rem
                        background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                        border: none
                        outline: none
                        color: #F0F3F4
                        box-sizing: border-box
                        transition: .3s
                        font-size: 17px
                        //margin-bottom: 1rem
                        @media screen and (max-width:800px)
                            padding: 0.2rem 0.5rem
                            height: fit-content
                            border-top-left-radius: 0.3rem
                            border-bottom-left-radius: 0.3rem
                            border-bottom-right-radius: 0.3rem
                            border-top-right-radius: 1px
                    .input-password-signup:focus
                        border-color: #887AB8
                        box-shadow: 0 0 8px 0 #887AB8
            .password-not
                margin-top: 0.6vw
                font-size: 1.2vw
                font-weight: 350
                margin-left: 2vw
                margin-bottom: 1vw

            .phone-number
                display: flex
                padding-left: 2vw
                padding-right: 2vw
                margin-top: 2rem
                justify-content: space-between
                @media screen and (max-width:800px)
                    font-size: 3vw
                    margin-top: 1.5rem

                .label-phonenumber-signup
                    padding-left: 0.3rem
                    margin-top: 0.5rem
                    font-size: 1.2vw
                    font-weight: 350
                    color: #887AB8
                    @media screen and (max-width:800px)
                        font-size: 3vw
                        margin-top: 0

                /* Chrome, Safari, Edge, Opera */
                .input-phonenumber-signup::-webkit-outer-spin-button,
                .input-phonenumber-signup::-webkit-inner-spin-button
                    -webkit-appearance: none
                    margin: 0

                /* Firefox */
                .input-phonenumber-signup[type=number]
                    mozappearance: textfield

                .input-phonenumber-signup
                    width: 80%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    color: #F0F3F4
                    outline: none
                    box-sizing: border-box
                    transition: .3s
                    font-size: 17px
                    margin-bottom: 1rem
                    @media screen and (max-width:800px)
                        padding: 0.2rem 0.5rem
                        height: fit-content
                        width: 90%
                        border-top-left-radius: 0.3rem
                        border-bottom-left-radius: 0.3rem
                        border-bottom-right-radius: 0.3rem
                        border-top-right-radius: 1px
                        align-self: center
                .input-phonenumber-signup:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8
        .login
            display: flex
            flex-direction: column
            width: 100%
            .contenedor-botones-dialogo
                display: flex
                width: 100%
                justify-content: space-evenly
                font-size: 1em

                .boton-dialogo
                    border-top-right-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.7em 2em
                    background-color: $secundario
                    color: white
                    border: none
                    font-weight: 500
                    margin: 1em 0
                    outline: none
                    cursor: pointer
                    font-size: 1.1em
                    width: fit-content
                    @media screen and (max-width:800px)
                        font-size: 3em
            .email
                display: flex
                flex-direction: column
                padding-left: 2vw
                padding-right: 2vw
                margin-top: 1em
                @media screen and (max-width:800px)
                    margin-top: 0.5em

                .login-label
                    padding-left: 0.6rem
                    color: #887AB8
                    margin-bottom: 0.7rem
                    font-size: 1.2vw
                    font-weight: 350
                    @media screen and (max-width:800px)
                        font-size: 3vw
                .input-email-login
                    width: 100%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    outline: none
                    color: #F0F3F4
                    box-sizing: border-box
                    transition: .3s
                    font-size: 17px
                    margin-bottom: 0.7rem
                    @media screen and (max-width:800px)
                        padding: 0.2rem 0.5rem
                        height: fit-content
                        width: 100%
                        border-top-left-radius: 0.3rem
                        border-bottom-left-radius: 0.3rem
                        border-bottom-right-radius: 0.3rem
                        border-top-right-radius: 1px
                .input-email-login:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8
            .password
                display: flex
                flex-direction: column
                padding-left: 2vw
                padding-right: 2vw
                margin-top: 1rem
                .password-label
                    padding-left: 0.6rem
                    margin-bottom: 0.7rem
                    color: #887AB8
                    font-size: 1.2vw
                    font-weight: 350
                    @media screen and (max-width:800px)
                        font-size: 3vw
                .input-login-password
                    width: 100%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    outline: none
                    color: #F0F3F4
                    box-sizing: border-box
                    transition: .3s
                    font-size: 17px
                    margin-bottom: 2rem
                    @media screen and (max-width:800px)
                        margin-bottom: 1rem
                        padding: 0.2rem 0.5rem
                        height: fit-content
                        width: 100%
                        border-top-left-radius: 0.3rem
                        border-bottom-left-radius: 0.3rem
                        border-bottom-right-radius: 0.3rem
                        border-top-right-radius: 1px
                .input-login-password:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8
        .password-sc
            display: flex
            flex-direction: column
            width: 100%
            .contenedor-botones-dialogo
                display: flex
                width: 100%
                justify-content: space-evenly
                font-size: 1em

                .boton-dialogo
                    border-top-right-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.7em 2em
                    background-color: $secundario
                    color: white
                    border: none
                    font-weight: 500
                    margin: 1em 0
                    outline: none
                    cursor: pointer
                    font-size: 1.1em
                    width: fit-content
                    @media screen and (max-width:800px)
                        font-size: 3em
            .email
                display: flex
                flex-direction: column
                padding-left: 2vw
                padding-right: 2vw
                .label-email-password
                    padding-left: 0.6rem
                    margin-bottom: 0.7rem
                    color: #887AB8
                    font-size: 0.8rem
                    font-weight: 350
                    @media screen and (max-width:800px)
                        margin-bottom: 0.4rem

                .input-email-password
                    width: 100%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    outline: none
                    color: #F0F3F4
                    box-sizing: border-box
                    transition: .3s
                    font-size: 0.8rem
                    margin-bottom: 0.7rem
                .input-email-password:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8
            .signal
                color: $secundario
                padding-left: 2vw
                padding-right: 2vw
                margin-bottom: 1rem
                font-size: 0.7rem
                font-weight: 350
                text-align: center

            #red
                color: $terciario

        .change-password
            display: flex
            flex-direction: column
            width: 80%
            .contenedor-botones-change
                display: flex
                width: 100%
                justify-content: space-evenly
                font-size: 1em

                .boton-dialogo-change
                    border-top-right-radius: 10px
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    padding: 0.7em 4em
                    background-color: $secundario
                    color: white
                    cursor: pointer
                    border: none
                    font-weight: 500
                    margin: 1em 0
                    outline: none
                    cursor: pointer
                    font-size: 1.1em
                    width: fit-content
                    @media screen and (max-width:800px)
                        font-size: 3em
            .email
                display: flex
                flex-direction: column
                padding-left: 2em
                padding-right: 3em
                .label-email-password
                    padding-left: 0.6rem
                    margin-bottom: 0.7rem
                    color: #887AB8
                    font-size: 1em
                    font-weight: 350
                    @media screen and (max-width:800px)
                        font-size: 2em
                    @media screen and (max-width:500px)
                        font-size: 3.5em
                .input-email-password
                    width: 100%
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                    height: 1.7rem
                    border-bottom-right-radius: 10px
                    padding: 0.5rem 0.8rem
                    background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
                    border: none
                    outline: none
                    color: #F0F3F4
                    box-sizing: border-box
                    transition: .3s
                    font-size: 17px
                    margin-bottom: 0.7rem
                .input-email-password:focus
                    border-color: #887AB8
                    box-shadow: 0 0 8px 0 #887AB8

        .label
            text-align: center
            font-size: 1.5em
            color: $secundario
            margin-bottom: 1em
            @media screen and (max-width:800px)
                font-size: 2em
            @media screen and (max-width:500px)
                font-size: 3.5em
        .label-pointer
            text-align: center
            font-size: 1.2em
            font-weight: 350
            color: $secundario
            margin-bottom: 1em
            color: #887AB8
            cursor: pointer
            @media screen and (max-width:800px)
                font-size: 2.5em
            @media screen and (max-width:500px)
                font-size: 3.5em
