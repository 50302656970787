.contenedor-info-tour
    font-size: 1em
    margin: 2em 0 4em
    width: 50%
    @import ./lista-lugares
    @media screen and ( max-width:  1000px)
        width: 100%
    >.titulo-info-tour
        font-size: 2em
        text-transform: uppercase
        width: 60%
        letter-spacing: 3px
        min-height: 2em
        word-break: break-word
        margin-bottom: 1em
        @media screen and ( max-width:  1000px)
            width: 100%
    >.texto-info-tour
        font-size: 0.95em
        width: 90%
        overflow-wrap: break-word
        min-height: 5em
        word-break: break-word
        margin-bottom: 2em

        a
            text-decoration: underline

        ol
            padding-left: 22px

        ul
            padding-left: 18px

    >.contenedor-ciudad-categoria-info-tour
        font-size: 1.2em
        display: grid
        grid-template-columns: 1fr 3.5fr
        row-gap: 0.2em
        column-gap: 0.5em
        min-height: 2.5em
        width:80%
        margin-bottom: 1.5em
    >.titulo-lugares-tour
        font-size: 1.4em
        letter-spacing: 1px
        text-transform: uppercase
        min-height: 1.5em
        margin-bottom: 3em
        width: 30%
    >.campo-skeleton
        border-radius: 0.5em
        background-color: #ddd
    