.contenedor-galeria
    width: 100%

    .recursos-galeria
        width: 80%
        margin: 1.5em auto
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(13em, 1fr))
        column-gap: 20px
        grid-gap: 1em

        @media screen and ( max-width: 550px )
            width: 95%

        .recurso-galeria
            width: 13em
            height: 9em
            margin: 1em auto
            +border-radius(0.7em)
            border-top-left-radius: 0.15em
            overflow: hidden
            position: relative
            cursor: pointer

            .contenedor-video-mini
                width: 100%
                height: 100%

                iframe
                    width: 100%
                    height: 100%

            .capa-falsa
                position: absolute
                width: 100%
                height: 100%
                top: 0
                left: 0

        img
            object-fit: cover

    // @media screen and ( max-width: 800px )
    //     grid-template-columns: repeat(2, 1fr)
        

    .contenedor-recurso-grande
        width: 100%
        height: 100vh
        position: fixed
        top: 0
        left: 0
        background: rgba(0, 0, 0, 0.3)
        z-index: 1000
        
        .fondo-cerrar
            position: absolute
            top: 0
            left: 0
            width: 100vw
            height: 100vh

        .recurso-galeria
            width: 60%
            height: 75%
            position: relative

            img
                max-width: 100%
                max-height: 100%
                min-width: 55%
                object-fit: cover
                +border-radius(1em)

            .contenedor-video-mini
                width: 100%
                height: 100%
                +border-radius(1em)
                overflow: hidden

                iframe
                    width: 100%
                    height: 100%

            @media screen and ( max-width: 800px )
                width: 90%
                height: 50%
                +border-radius(1em)
                overflow: hidden

                .contenedor-video-mini
                    +border-radius(0em)
            
        
        .flecha
            width: 3em
            height: 3em
            background: none
            border: none
            margin: 1em
            cursor: pointer
            outline: none
            position: fixed

            div
                width: 1.5em
                height: 1.5em
                border-bottom: solid 0.6em $primario
                border-left: solid 0.6em $primario
                transform: rotate(45deg)
                box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.5)

        .flecha-derecha
            right: 15%
            top: 45%

            div
                border: none
                border-top: solid 0.6em $primario
                border-right: solid 0.6em $primario
                transform: rotate(45deg)
                box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.5)
            
            @media screen and ( max-width: 800px )
            right: 10%
            top: 75%

        .flecha-izquierda
            left: 15%
            top: 45%

            @media screen and ( max-width: 800px )
            left: 10%
            top: 75%

        .cerrar-recurso
            width: 1.3em
            height: 1.3em
            background: none
            border: none
            cursor: pointer
            outline: none
            position: fixed
            top: 1em
            right: 1em
            color: white
            font-size: 2em
            background-color: $primario
            +border-radius(100%)
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5)


