.contenedor-item-lugar-tour
    font-size: 1em
    position: relative
    height: 5em
    >.circulo-item-lugar
        +border-radius(100%)
        background-color: $primario
        font-size: 1em
        width: 1.7em
        height: 1.7em
        position: absolute
        top: -0.8em
        left: -0.8em
    >.texto-item-lugar
        font-size: 1em
        width: 50%
        min-height: 1.2em
        max-width: 10em
    >.imagen-item-lugar
        width: 5em
        height: 5em
        margin-right: 1em
        // object-fit: cover
        border-radius: 20% !important
    >.campo-skeleton
        border-radius: 5em
        background-color: #ddd
