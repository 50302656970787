.contenedor-lista-lugares
    font-size: 1em
    width: 85%
    position: relative
    padding-left: 1.5em
    @media screen and ( max-width: 1200px )
        width: 90%
        align-self: center
    @media screen and ( max-width: 600px )
        font-size: 2.6vw
        
    >.grilla-lugares-tour
        width: 100%
        margin-top: -1em
        display: grid
        grid-template-columns: 1fr 1fr
        row-gap: 3em
        column-gap: 1em
        @import ./item-lugar
    >.flecha-lista-lugares
        position: absolute
        width: 10%
        height: 100%
        font-size: 2em
        cursor: pointer
        user-select: none
        background: none
        border: none
        outline: none
        >div
            width: 0.4em
            height: 0.4em
            border-bottom: solid 0.2em $terciario
            border-left: solid 0.2em $terciario
            transform: rotate(45deg)
        >.flecha-derecha
            border: none
            border-top: solid 0.2em $terciario
            border-right: solid 0.2em $terciario
    >.flecha-izquierda-lista-lugares
        left: -3.5vw
    >.flecha-derecha-lista-lugares
        right: -2.5vw

