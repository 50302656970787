.contenedor-carrusel
    margin: 4em 0 1em
    width: 100%

    .contenedor-videos
        width: 65vw
        overflow: hidden

        .div-margin
            flex-wrap: nowrap
            transition: 1s all ease

.contenedor-video
    width: 65vw
    height: 24em
    border-radius: 2em
    border-top-left-radius: 0.2em
    overflow: hidden
    flex: 0 0 auto
    transition: 1s all ease


.flecha
    width: 2em
    height: 2em
    background: none
    border: none
    margin: 3em
    cursor: pointer
    outline: none

    div
        width: 1.1em
        height: 1.1em
        border-bottom: solid 0.6em $secundario
        border-left: solid 0.6em $secundario
        transform: rotate(45deg)

    .flecha-derecha
        border: none
        border-top: solid 0.6em $secundario
        border-right: solid 0.6em $secundario

.circulos-carrusel
    width: 100%
    margin-bottom: 3em
    
    .circulo
        background: none
        border: none
        width: 0.9em
        height: 0.9em
        background-color: $secundario
        margin: 0 1.3em
        +border-radius(100%)
        cursor: pointer
        outline: none

    .seleccionado
        background-color: $terciario
