.tarjeta-pagos
    display: flex
    flex-direction: column
    width: 90%
    margin: auto
    padding-left: 2vw
    padding-right: 2vw
    align-items: center
    @import ./components/metodo-pago
    .texto-morado
        align-self: center
        justify-content: center
        align-items: center
        color: $primario
        font-size: 1em
        padding-left: 2em
        padding-right: 2em
        @media screen and (max-width:800px)
            padding: 0
        
    .titulo-morado
        align-self: center
        justify-content: center
        align-items: center
        font-weight: 500
        font-size: 1.1em
        margin-bottom: 2em
        margin-right: 2em
        margin-left: 2em
        text-align: center
        @media screen and (max-width:800px)
            margin: 0

    .titulo-azul
        margin-top: 1em
        align-self: center
        justify-content: center
        align-items: center
        font-weight: 550
        font-size: 1.2em
    .contenedor-boton-mas
        display: flex
        margin-top: 0.5vw
        width: 100%
        justify-content: center
        margin-bottom: 3vw
        font-size: 1em
        .agregar-metodo
            width: fit-content
            height: fit-content
            border-top-left-radius: 0.3rem
            border-bottom-left-radius: 1rem
            border-bottom-right-radius: 1rem
            border-top-right-radius: 1rem
            padding: 0.7rem 2.2rem
            background: linear-gradient(rgba(179, 104, 169, 0.3) ,rgba(136, 122, 184, 0.5))
            border: none
            cursor: pointer
            outline: none
            display: flex
            margin-top: 1rem
            align-content: center
            justify-content: center
            box-sizing: border-box
            transition: .3s
            font-size: 17px
            margin-bottom: 0.7rem
            @media screen and (max-width:800px)
                font-size: 3.5vw
                margin-top: 0.5rem
                padding: 0.4rem 1rem
                border-top-left-radius: 0.2rem
                border-bottom-left-radius: 0.7rem
                border-bottom-right-radius: 0.7rem
                border-top-right-radius: 0.7rem
            .mas
                cursor: pointer
                box-sizing: border-box
                border: 3px solid white
                color: white
                padding: 0rem 0.2rem
                border-radius: 0
                font-weight: 550
                border-radius: 0
                font-size: 2em
