.tarjeta-tour-liked {
    font-size: 1em;
    width: 15.625em;
    height: 20em;
    overflow: hidden;
    border-radius: 2em;
    border-bottom-left-radius: 0.3em;
    position: relative;
  
    .img-tour {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  
    .contenedor-textos-tour {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent linear-gradient(180deg, #ffffff00 0%, #2c2250ea 100%) 0% 0% no-repeat padding-box;
  
      .titulo-tarjeta-tour {
        margin: 0 0;
        margin-top: 0px;
        
        .precio-foto {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
  
          img {
            position: absolute;
            right: 1em;
            top: 0.75em;
            width: 2.5em;
            height: 2.5em;
            object-fit: cover;
            border-radius: 50%;
            border: #eee1f4 2px solid;
          }
        }
  
        .label-tours-liked {
          font-size: 0.8em;
          font-weight: 500;
          color: white;
          border: none;
          border-bottom-right-radius: 50px;
          background-color: #58c7de;
          width: 40%;
          align-content: center;
          padding: 0.5em 1.5em 0.5em 1em;
          outline: none;
          height: 2em;
        }
        
        .boton-mas-tour {
          font-size: 2em;
          margin-right: 0.4em;
          font-weight: bold;
        }
      }
      .tarjeta-expandida {
        height: 100%;
  
        .detalle-tarjeta {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 100%;
  
          .boton-favorito-tour {
            cursor: pointer;
            font-size: 1.5em;
            margin-right: 0.4em;
            margin-top: 0.5em;
            margin-bottom: 3em;
            font-weight: bold;
            align-self: flex-end;
  
            :hover {
              color: red;
            }
  
            @media screen and (max-width: 500px) {
              margin-bottom: 1.5em;
            }
          }
  
          .contenedor-estrellas-tour {
            margin-top: 1em;
            width: fit-content;
          }
  
          @media screen and (max-width: 600px) {
            .contenedor-estrellas-tour {
              transform: scale(0.6);
            }
          }
  
          .titulo-detalle {
            font-size: 0.9em;
            font-weight: 550;
            border: none;
            justify-self: center;
            align-self: center;
            margin: 0 1em;
          }
  
          .descripcion-detalle {
            font-size: 0.7em;
            border: none;
            align-content: center;
            align-self: center;
            text-align: center;
            letter-spacing: 0.1em;
            margin: 0 1em;
            margin-top: 0.3rem;
          }
        }
  
        .boton-info {
          font-size: 0.8em;
          font-weight: bold;
          color: red;
          border: none;
          border-radius: 2em;
          border-top-left-radius: 0.2em;
          background-color: white;
          width: fit-content;
          align-self: center;
          padding: 0.6em 1.5em;
          outline: none;
          margin-bottom: 2em;
          user-select: none;
          cursor: pointer;
        }
  
        // @media screen and (max-width: 450px) {
        //   .boton-info {
        //     font-size: 2.75vw;
        //   }
        // }
      }
      
      .footer-tarjeta-tour {
        margin: 0 0;
        .texto-tours-liked {
          font-size: 0.8rem;
          font-weight: 550;
          border: none;
          width: fit-content;
          align-content: center;
          padding-left: 1em;
          margin-bottom: 0.7rem;
        }
      }
    }
  }
  