>.map-container {
    width: 45%;
    position: sticky;
    height: 80vh;
    right: 0;
    top: 0;
    transition: 0.5s all ease;
    margin-bottom: 2em;

    @import "./components/marker/marker.scss";
    @import "./components/person/person.scss";
}
