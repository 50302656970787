.cuenta-confirmada {
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 200;
    align-items: center;
    padding: 0.5em 0;
    background-color: $secundario;
    width: 100%;
    @include border-radius(0px 0px 20px 20px);

    .texto {
        width: 100%;
        color: white;
        margin-left: 1em;
        text-align: center;
    }

    .cerrar {
        margin-left: auto;
        padding: 0.5em 1em;
        cursor: pointer;
    }
}