

.contenedor-about
    font-size: 16px
    width: 100%
    overflow: hidden
    @import ./components/encabezado/encabezado
    @import ./components/features/features
    @import ./components/logros/logros
    @import ./components/descripcion/descripcion
    @import ./components/carruselVideos/carruselVideos
    @media screen and ( max-width: 500px )
        font-size: 3.2vw
        