*
    margin: 0
    padding: 0
    font-family: 'Ubuntu', sans-serif
    outline: none

body
    font-size: 16px

a
    text-decoration: none

#root
    display: flex
    flex-direction: column
    min-height: 100vh

.flex
    display: flex

.center
    display: flex
    justify-content: center
    align-items: center

.jc-space-around
    justify-content: space-around

.jc-space-between
    justify-content: space-between

.jc-end
    justify-content: flex-end

.jc-start
    justify-content: flex-start

.jc-center
    justify-content: center

.ai-center
    align-items: center

.ai-start
    align-items: flex-start

.ai-end
    align-items: flex-end

.as-end
    align-self: flex-end

.as-start
    align-self: flex-start

.as-center
    align-self: center

.flex-column
    display: flex
    flex-direction: column

.flex-reverse
    flex-direction: row-reverse

.flex-column-reverse
    flex-direction: column-reverse

.text-align-start
    text-align: start

.text-align-end
    text-align: end

.text-align-center
    text-align: center

.text-align-justify
    text-align: justify

.texto-tarjetas
    font-size: 0.9em
    font-family: Ubuntu
    letter-spacing: 2px

.texto-mayuscula
    text-transform: uppercase

.texto-titulo-tarjetas
    font-size: 1.3em
    letter-spacing: 2.8px
    font-family: Ubuntu
    letter-spacing: 2px
    text-transform: uppercase

.texto-rojo
    color: $terciario

.texto-morado
    color: $primario

.texto-azul
    color: $secundario

.texto-blanco
    color: white

.fondo-primario
    background: $primario 

.fondo-blanco
    background: white 

.fondo-secundario
    background: $secundario

.fondo-terciario
    background: $terciario

.titulo-parrafo
    font-size: 1.65em
    font-weight: 450

.bold
    font-weight: bold
.bold-300
    font-weight: 300
.bold-400
    font-weight: 400
.bold-500
    font-weight: 500
.bold-600
    font-weight: 600

.texto-tarjeta
    font: normal normal 300 20px/22px
    font-size: 0.8em
    font-family: Ubuntu
    letter-spacing: 2px

.margin-bottom-auto
    margin-bottom: auto

.globo
    width: 100%
    height: 100%
    position: relative

    img
        width: 100%
        height: 100%
        object-fit: contain

    div
        position: absolute
        top: 0
        left: 0
        width: 77%
        height: 100%

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number] 
  -moz-appearance: textfield


@import ../screens/general/globos/globos
